// SMACSS Base Rules
// ----------------------------
// Base styles include setting heading sizes, default link styles, default
// font styles, and body backgrounds.
// There should be no need to use !important in a Base style.
// ==========================================================================

// Set box-sizing globally to handle padding and border widths
html {
  &[data-loading="true"] a {
    pointer-events: none;
    opacity: 0.7;
  }

  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  height: 100%;

  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

// Default body styles
body {
  position: relative;
  height: 100%;
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
  //letter-spacing: var(--text-letter-spacing);
  // > don't use font-smoothing for light text on dark background!!
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: var(--color-neutral-03);
}

img {
  height: auto;
  max-width: 100%;
  width: 100%;
}

a {
  color: black;
  text-decoration: none;
}

.marginHorizontal {
  margin: var(--space-size-2-5) 0;
}

.marginBottom {
  margin-bottom: var(--space-size-2-5);
}
