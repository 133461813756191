:root {
  // Color
  // ------------------
  --color-primary: #03234b;
  --color-secondary: #0047b2;
  --color-tertiary: #3cb4e6;

  --color-neutral-01: #ffffff;
  --color-neutral-02: #f4f7fa;
  --color-neutral-03: #f7f8fa;
  // --color-neutral-04: #dbdee2;
  --color-neutral-05: #dbdee2;
  --color-neutral-06: #a6adb5;
  --color-neutral-07: #898f99;
  --color-neutral-08: #767c84;
  // --color-neutral-09: #525a63;
  --color-neutral-10: #525a63;
  --color-neutral-11: #3b424a;
  --color-neutral-12: #2f3337;
  --color-neutral-13: #1f1f1f;

  --color-tonal: #ffd200;
  --color-success: #1e8537;
  --color-error: #cc0000;

  --color-shadow: rgba(33, 25, 53, 0.06);
  --color-bg-disable: #eeeff1;
  --color-text-disable: #bdbdbd;

  --btn-primary-hover: #ffdb33;
  --btn-primary-active: #f5ca00;
  --btn-dark-hover: #042e62;
  --btn-dark-active: #021b3b;
  --btn-secondary-hover: #eeeff1;
  --color-border-01: #e8eaf4;

  // Spacing
  // ------------------
  --space-unit: 0.8rem;
  --space-size-0-5: calc(0.5 * var(--space-unit)); // 4px
  --space-size-1: calc(1 * var(--space-unit)); // 8px
  --space-size-1-5: calc(1.5 * var(--space-unit)); // 12px
  --space-size-2: calc(2 * var(--space-unit)); // 16px
  --space-size-2-5: calc(2.5 * var(--space-unit)); // 20px
  --space-size-3: calc(3 * var(--space-unit)); // 24px
  --space-size-4: calc(4 * var(--space-unit)); // 32px
  --space-size-5: calc(5 * var(--space-unit)); // 40px
  --space-size-6: calc(6 * var(--space-unit)); // 48px
  --space-size-7: calc(7 * var(--space-unit)); // 56px
  --space-size-8: calc(8 * var(--space-unit)); //64px
  --space-size-9: calc(9 * var(--space-unit)); //72px
  --space-size-10: calc(10 * var(--space-unit)); // 80px
  --space-size-12: calc(12 * var(--space-unit)); // 96px

  --border-radius: 0.6rem;
}
